import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import ReactGA from 'react-ga';
// import createHistory from 'history/createBrowserHistory';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { createBrowserHistory } from 'history';
// import PrintInvoice from './views/ProfileContent/PrintInvoice/PrintInvoice'
ReactGA.initialize('UA-133682637-3');
const history = createBrowserHistory();
ReactGA.set({ page:history.location.hash });
ReactGA.pageview(history.location.hash);
history.listen((location, action) => {
  ReactGA.set({ page:history.location.hash });
  ReactGA.pageview(history.location.hash);
});

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});
// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const ForgotPassword = Loadable({
  loader: () => import('./views/Pages/ForgotPassword'),
  loading
});

const ResetPassword = Loadable({
  loader: () => import('./views/Pages/ResetPassword'),
  loading
});

const EmailVerification = Loadable({
  loader: () => import('./views/Pages/EmailVerification'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});


class App extends Component {

  render() {
    return (
      
      <HashRouter>
        <Switch>
          <Route exact path="/linkedin" component={LinkedInPopUp} />
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/forgotpassword" name="Forgot Password Page" component={ForgotPassword} />   
          <Route exact path="/resetpassword/:id" name="Reset Password Page" component={ResetPassword} />
          <Route exact path="/emailverification/:id" name="Email VerificationPage" component={EmailVerification} />   
          {/* <Route exact path="/invoice" name="Print Invoice" component={PrintInvoice}/>                       */}
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
